<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <c-tree-table
          ref="processTable2"
          title="공사형태별 공종 목록"
          parentProperty="upProcessCd"
          customID="processCd"
          :columns="grid.columns"
          :data="grid.data"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :expandAll="true"
          :filtering="false"
          @rowClick="rowClick"
        >
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <q-form ref="editForm">
          <c-card title="공종 상세" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && this.parentProcess !== null && selectedProcessCd"
                  label="하위공종"
                  icon="add"
                  @btnClicked="addChild('')"
                />
                <c-btn
                  v-if="editable && this.parentProcess !== null"
                  label="신규"
                  icon="add"
                  @btnClicked="addChild('new')"
                />
                <c-btn
                  v-if="editable"
                  :disabled="!saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback"
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="공종명"
                  name="processName"
                  v-model="data.processName"
                >
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  label="공종관리번호"
                  name="processNo"
                  v-model="data.processNo"
                >
                </c-text>
              </div>
              <div class="col-12">
                <c-text
                  :afterIcon="editable ? [
                    { name: 'search', click: true, callbackName: 'selectUpMenu', color: 'light-blue' },
                    { name: 'close', click: true, callbackName: 'removeUpMenu', color: 'red' }
                  ] : null"
                  :disabled="true"
                  :editable="editable && dataeditable"
                  label="상위 공종"
                  name="upProcessName"
                  v-model="data.upProcessName"
                  @selectUpMenu="() => { ;isMenuOpen = true }"
                  @removeUpMenu="removeUpMenu">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="정렬순서"
                  name="sortOrder"
                  v-model="data.sortOrder"
                >
                </c-text>
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-12">
                <c-textarea
                  :counter="true"
                  :editable="editable && dataeditable"
                  label="공종 설명"
                  name="processDesc"
                  :rows="5"
                  v-model="data.processDesc"
                >
                </c-textarea>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
    <q-dialog
      ref="menuDialog"
      persistent
      v-model="isMenuOpen">
      <q-card class="menu-card" style="width: 400px; max-width: 50vw;">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-7 text-white">
          <div class="text-h6">공종</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectMenu"></q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-item>
          <q-item-section>
            <q-item-label caption>
              <q-chip outline color="primary" text-color="white">
                {{processName}}
              </q-chip>
            </q-item-label>
          </q-item-section>
        </q-item>
        <q-separator></q-separator>

        <q-card-section class="menu-content p-2">
          <q-tree
            ref="menuTree"
            :nodes="menuTree"
            node-key="processCd"
            label-key="processName"
            children-key="children"
            no-nodes-label="공종이 없습니다."
            no-results-label=""
            :default-expand-all="false"
            selected-color="primary"
            :selected.sync="selectedMenu"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import arrayToTree from 'array-to-tree';
export default {
  name: 'process-unit',
  props: {
    parentProcess: {
      type: Object,
      default: () => ({
        constructTypeCd: null
      })
    },
  },
  data() {
    return {
      attachable: false,
      processName: '공종을 선택하세요.',
      menuTree: [],
      selectedMenu: null,
      selectedSysMenuId: '',
      isMenuOpen: false,
      grid: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '공종',
            align: 'left',
            sortable: true,
          },
          {
            name: 'processNo',
            field: 'processNo',
            label: '공종관리번호',
            align: 'center',
            style: 'width: 100px',
            sortable: true,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width: 70px',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        plantCd: null,
        processCd: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '',
        processDesc: '',
        managementDepts: '',
        sortOrder: '',
        useFlag: 'Y',
        psmFlag: 'Y',
        workCycleCd: null,
        workingTime: null,
        col1: [],
      },
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      lvl1Check: false,
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.mdm.process.insert.url,
      saveType: 'POST',
      searchUrl: '',
      selectedProcessCd: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  watch: {
    parentProcess() {
      if (this.parentProcess) {
        this.getProcessList({
          constructTypeCd: this.parentProcess.constructTypeCd,
        });
      } else {
        this.reset();
        this.rowNotSelected = true;
        this.grid.data = [];
      }
    },
    selectedMenu() {
      let data = this.$refs['menuTree'].getNodeByKey(this.selectedMenu);
      this.processName = data.processName;
    },
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.mdm.process.list.url;
      this.detailUrl = selectConfig.mdm.process.get.url;
      this.checkUrl = selectConfig.mdm.process.check.url;
      this.insertUrl = transactionConfig.mdm.process.insert.url;
      this.updateUrl = transactionConfig.mdm.process.update.url;
      this.deleteUrl = transactionConfig.mdm.process.delete.url;
      if (this.parentProcess && this.parentProcess.constructTypeCd) {
        this.getProcessList({
          constructTypeCd: this.parentProcess.constructTypeCd,
        });
      }
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upProcessCd',
        customID: 'processCd',
      });
      return tree;
    },
    selectMenu() {
      if (this.selectedMenu) {
        let data = this.$refs['menuTree'].getNodeByKey(this.selectedMenu);
        this.data.upProcessCd = data.processCd;
        this.data.upProcessName = data.processName;
        this.$refs['menuDialog'].hide();
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '공종을 선택하세요.', // 메뉴를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      }
    },
    removeUpMenu() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '상위공종이 없는 경우 최상위 공종으로 들어가게 됩니다.\n\r진행하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.data.upProcessCd = '';
          this.data.upProcessName = '';
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[1];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getProcessList(row) {
      if (row.constructTypeCd !== undefined) {
        this.getList(row);
        this.reset();
      } else {
        this.reset();
        this.rowNotSelected = true;
        this.grid.data = [];
      }
    },
    getList(row) {
      this.rowNotSelected = true;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        constructTypeCd: row.constructTypeCd,
      };
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        if (_result.data.length > 0) {
          this.menuTree = this.convertTree(_result.data);
        } else {
          this.menuTree = [];
        }
      });
      this.saveable = false;
      this.deleteable = false;
      this.attachable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.rowNotSelected = false;
      this.updateMode = true;
      this.selectedProcessCd = row.processCd;
      this.$http.url = this.$format(this.detailUrl, this.selectedProcessCd);
      this.$http.type = 'GET';
      this.$http.request(
        (_result) => {
          this.data = _result.data;
          this.saveUrl = this.updateUrl;
          this.saveType = 'PUT';
          this.attachable = true;
          this.dataeditable = true;
        },
        () => {}
      );
    },
    addChild(_type) {
      this.saveable = true;
      this.deleteable = false;
      this.lvl1Check = false;
      this.lvl2Check = true;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.attachable = true;
      this.dataeditable = true;
      this.data = {
        companyCd: this.$store.getters.user.companyCd,
        constructTypeCd: this.parentProcess.constructTypeCd,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: (this.data.processCd != '' ? (_type == 'new' ? '' : this.data.processCd) : ''),
        upProcessName: (this.data.processName != '' ? (_type == 'new' ? '' : this.data.processName) : ''),
        processLevelCd: '20',
        processDesc: '',
        sortOrder: '',
        useFlag: 'Y',
        col1: [],
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.attachable = false;
      this.dataeditable = false;
      this.data = {
        companyCd: this.$store.getters.user.companyCd,
        processCd: '',
        processNo: '',
        processName: '',
        upProcessCd: '',
        upProcessName: '',
        processLevelCd: '',
        processDesc: '',
        sortOrder: '',
        useFlag: 'Y',
        col1: [],
      };
    },
    addrow() {},
    removeRow() {},
    downloadFlow() {},
    saveData() {
      this.$refs['editForm'].validate().then((_result) => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.processCd);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            console.log(_result);
            this.isSave = !this.isSave;
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {},
      });
    },
    /* eslint-disable no-unused-vars */
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getProcessList({
          constructTypeCd: this.parentProcess.constructTypeCd,
        });
      } else {
        this.getProcessList({
          constructTypeCd: this.parentProcess.constructTypeCd,
        });
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getProcessList({ constructTypeCd: this.parentProcess.constructTypeCd });
      this.selectedProcessCd = '';
    },
  },
};
</script>
